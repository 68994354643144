.app {
  color: #fff;
  width: 100%;
	margin: 0;
	display: table-cell;
	vertical-align: middle;
}

.root:before, .app:before {
	display: table;
	content: " ";
}

.root:after, .app:after {
	display: table;
	content: " ";
	clear: both;
}

.logo {
  height: 220px;
}

h1.h1Title {
  color: #fff;
  font-size: 4rem;
}

.textFieldLg {
  width: 250px;
}

.containerCentered {
  text-align: center;
}

.containerCentered > * {
  padding: 8px 0;
}

button span {
  color: #fff;
}

a {
  text-decoration: none;
}

.flexSpacer {
  flex-grow: 1;
}

.anchorToBottom {
  position: fixed;
  bottom: 0;
}