@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/font/HelveticaNeue.eot'); /* IE9 Compat Modes */
  src: local('Helvetica'), local('Helvetica'),
       url('./assets/font/HelveticaNeue.woff') format('woff'), /* Modern Browsers */
       url('./assets/font/HelveticaNeue.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/font/HelveticaNeue-Bold.eot'); /* IE9 Compat Modes */
  src: local('Helvetica'), local('Helvetica'),
       url('./assets/font/HelveticaNeue-Bold.woff') format('woff'), /* Modern Browsers */
       url('./assets/font/HelveticaNeue-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
	height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  background: url(./assets/img/dh-patient-web-bg.png) no-repeat 0% 20% fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#root {
	text-align: center;
	width: 100%;
	display: table;
	height: 100vh;
	padding: 0;
	margin-right: auto;
	margin-left: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
