.webChat {
  height: calc(100vh - 80px);
  width: 100%;
  text-align: left;
}

.webChat .main {
  border: none;
}

.webChat input {
  background-color: #000;
  padding-left: 40px;
  font-size: 1.2em;
}

::placeholder {
  color:white;
  opacity: 1; 
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}

.webChat .avatar img {
  width: 165%;
  top: 92%;
  left: 51%;
}

.webChat .from-user .avatar img {
  width: auto;
  top: 92%;
  left: 51%;
}

.webChat .avatar {
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 0% !important;
  background-color: #003D61;
}

.webChat .avatar .image{
  height: 90% !important;
  width: 90% !important;
  left:auto;
  top:auto;
  border-radius: 50%;
  background-color: #4F95DF;
  overflow: visible;
}

.webChat .from-user .bubble{
  min-height: 58px !important;
  text-align: right;
  max-width: 300px !important;
}

.webChat .bubble{
  min-height: 58px !important;
  text-align: left;
  max-width: 300px !important;
}

.webChat li{
  margin-bottom: 20px;
}

.webChat .row .timestamp{
  display: none !important;
}

.microphone {
  position: absolute;
  bottom: 15px;
  right: 10%;
}

.microphone img {
  width: 100px;
  cursor: pointer;
}

.webChat svg{
  fill:white !important;
}